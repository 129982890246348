import { forwardRef, useRef, ChangeEvent } from 'react'

import { convertNumber } from '../../../lib/convertNumber'
import { CountInput, CountInputProps } from '../CountInput'

export type Props = Pick<
  CountInputProps,
  | 'name'
  | 'autoFocus'
  | 'inputSize'
  | 'fontSize'
  | 'fieldStyle'
  | 'isError'
  | 'width'
  | 'radius'
  | 'onFocus'
> & {
  minWidth?: number
  maxWidth?: number
  growCharacterNumber?: number
  value?: number | string
  onChangeInputValue?: (value: string) => void
  setValueOnChange: (type: 'up' | 'down') => void
  allowDecimal?: boolean
  positive?: boolean
}

export const ProgressNumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      minWidth = 60,
      maxWidth = 107,
      growCharacterNumber = 3,
      value = 0,
      setValueOnChange,
      onChangeInputValue,
      name,
      allowDecimal = true,
      positive = false,
      ...props
    },
    ref,
  ) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const isComposing = useRef(false)

    const getInputWidth = (text: string): string => {
      if (!ctx) {
        return `${minWidth}px`
      }
      const textWidth = ctx.measureText(text).width
      if (text.length <= growCharacterNumber) {
        return `${minWidth}px`
      }
      const styleWidth = Math.floor(minWidth + textWidth - 7)
      if (styleWidth > maxWidth) {
        return `${maxWidth}px`
      }
      return `${styleWidth}px`
    }

    const changeWidthByTextLength = (e: ChangeEvent<HTMLInputElement>) => {
      const t = e.currentTarget
      t.style.width = getInputWidth(t.value)
    }

    const validateValue = (newValue: string): boolean => {
      const convertedValue = convertNumber(newValue)
      return (
        newValue === '' ||
        (allowDecimal && Number.isFinite(Number(convertedValue))) ||
        (!allowDecimal && Number.isInteger(Number(convertedValue))) ||
        (!positive && convertedValue === '-')
      )
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
      changeWidthByTextLength(e)

      if (isComposing.current) {
        onChangeInputValue?.(newValue)
      } else {
        const convertedValue = convertNumber(newValue)
        if (validateValue(newValue)) {
          onChangeInputValue?.(convertedValue)
        }
      }
    }

    const handleCompositionStart = () => {
      isComposing.current = true
    }

    const handleCompositionEnd = (e: React.CompositionEvent<HTMLInputElement>) => {
      isComposing.current = false
      const newValue = e.currentTarget.value
      const convertedValue = convertNumber(newValue)
      if (validateValue(newValue)) {
        onChangeInputValue?.(convertedValue)
      }
    }

    return (
      <CountInput
        required
        type="text"
        fontSize="medium"
        fieldStyle="bottomLine"
        ref={ref}
        name={name}
        value={value}
        width={getInputWidth(String(value))}
        onChange={handleChange}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
        onCountUp={() => setValueOnChange('up')}
        onCountDown={() => setValueOnChange('down')}
        {...props}
      />
    )
  },
)
