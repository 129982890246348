import { ThemeContext, ThemeValue } from 'grommet'
import { ChangeEvent, CompositionEvent, forwardRef, KeyboardEvent, useRef } from 'react'

import { convertNumber } from '../../../lib/convertNumber'
import { Input, InputProps } from '../Input'

const themeExtendValue: ThemeValue = {
  textInput: {
    container: {
      extend: {
        width: 'auto',
        outline: 'none',
      },
    },
  },
}

const inputCss = {
  padding: '0 8px',
}

export type Props = InputProps & {
  minWidth?: number
  maxWidth?: number
  growCharacterNumber?: number
  allowDecimal?: boolean
  positive?: boolean
  'data-testid'?: string
}

export const NumberInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      minWidth = 48,
      maxWidth = 107,
      growCharacterNumber = 2,
      defaultValue = 0,
      allowDecimal = true,
      positive = false,
      'data-testid': dataTestId,
      onChange,
      ...props
    },
    ref,
  ) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const preValue = useRef(String(defaultValue))
    const isComposing = useRef(false)

    const getInputWidth = (text: string): string => {
      if (!ctx) {
        return `${minWidth}px`
      }
      const textWidth = ctx.measureText(text).width
      if (text.length <= growCharacterNumber) {
        return `${minWidth}px`
      }
      const styleWidth = Math.floor(minWidth + textWidth - 23)
      if (styleWidth > maxWidth) {
        return `${maxWidth}px`
      }
      return `${styleWidth}px`
    }

    const filterFloatNegativeNumber = (e: KeyboardEvent<HTMLInputElement>) => {
      // IME入力中は処理をスキップ
      if (isComposing.current) {
        return
      }

      const convertValue = convertNumber(e.currentTarget.value)
      if (
        (allowDecimal && Number.isFinite(Number(convertValue))) ||
        (!allowDecimal && Number.isInteger(Number(convertValue))) ||
        (!positive && convertValue === '-')
      ) {
        preValue.current = convertValue
        e.currentTarget.value = convertValue
      } else {
        e.currentTarget.value = preValue.current
      }
      onChange?.(e as unknown as ChangeEvent<HTMLInputElement>)
    }

    // IME入力開始時
    const handleCompositionStart = () => {
      isComposing.current = true
    }

    // IME入力終了時
    const handleCompositionEnd = (e: CompositionEvent<HTMLInputElement>) => {
      isComposing.current = false
      // IME確定後に値を検証
      filterFloatNegativeNumber(e as unknown as KeyboardEvent<HTMLInputElement>)
    }

    return (
      <ThemeContext.Extend value={themeExtendValue}>
        <Input
          required
          type="text"
          fontSize="medium"
          fieldStyle="bottomLine"
          css={inputCss}
          ref={ref}
          defaultValue={defaultValue}
          onInput={filterFloatNegativeNumber}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          width={getInputWidth(String(props.value))}
          data-testid={dataTestId}
          {...props}
        />
      </ThemeContext.Extend>
    )
  },
)

NumberInput.displayName = 'NumberInput'
